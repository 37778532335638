import { lazy } from 'react';

// const Role = lazy(() => import('./Role'));

// const RoleConfig = {
//   settings: {
//     layout: {
//       config: {},
//     },
//   },
//   routes: [
//     {
//       path: 'dashboards/role/:roleId/*',
//       element: <Role />,
//     },
//   ],
// };

// export default RoleConfig;
