import { lazy } from 'react';

const Jobs  = lazy(() => import('./Jobs'));
const JobsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/jobs',
      element: <Jobs /> ,
    },
  ],
};

export default JobsConfig;
