import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import withReducer from "app/store/withReducer";

import reducer from "./store";

function WidgetsApp() {
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  return <Outlet />;
}

export default withReducer("widgetsApp", reducer)(WidgetsApp);
