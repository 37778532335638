import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { selectUser } from 'app/store/userSlice';
import axios from 'axios';

function UserMenu(props) {
  const user = useSelector(selectUser);
  const [userMenu, setUserMenu] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loginMethod, setLoginMethod] = useState(''); // State untuk menyimpan metode login

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  // Fetch user profile data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const respon = await axios.get(`/userprofiles/${user.id}`);
        let data = await respon.data.data;
        setProfile(data);
      } catch (error) {
        // Handle error if needed
      }
    };
    fetchData();
  }, [user.id]);

  // Periksa metode login ketika komponen di-mount
  useEffect(() => {
    const method = localStorage.getItem('login_method');
    setLoginMethod(method);
  }, []);

  return (
    <>
      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
        color="inherit"
      >
        <div className="hidden md:flex flex-col mx-4 items-end">
          <Typography component="span" className="font-semibold flex">
            {user.full_name}
          </Typography>
          <Typography className="text-11 font-medium capitalize" color="text.secondary">
            {user.role.toString()}
            {(!user.role || (Array.isArray(user.role) && user.role.length === 0)) && 'Guest'}
          </Typography>
        </div>

        {profile ? (
          <Avatar className="md:mx-4" alt="user photo" src={profile.avatarUrl} />
        ) : (
          <Avatar className="md:mx-4">{user.full_name}</Avatar>
        )}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {!user.role || user.role.length === 0 ? (
          <>
            <MenuItem component={Link} to="/sign-in" role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:lock-closed</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign In" />
            </MenuItem>
            <MenuItem component={Link} to="/sign-up" role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:user-add </FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign up" />
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem component={Link} to={`/apps/profile?tab=about`} onClick={userMenuClose} role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </MenuItem>
            {/* Tampilkan menu Change Password hanya jika login dengan username-password */}
            {loginMethod === 'username-password' && (
              <MenuItem component={Link} to="/apps/change-password" onClick={userMenuClose} role="button">
                <ListItemIcon className="min-w-40">
                  <FuseSvgIcon>heroicons-outline:adjustments</FuseSvgIcon>
                </ListItemIcon>
                <ListItemText primary="Change Password" />
              </MenuItem>
            )}
            <MenuItem
              component={NavLink}
              to="/sign-out"
              onClick={() => {
                userMenuClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign out" />
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
}

export default UserMenu;
