import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';
import ContactModel from '../model/ContactModel';
import EmployeeModel from '../model/EmployeeModel';

export const getContact = createAsyncThunk(
  'contactsApp/task/getContact',
  async (id, { dispatch, getState }) => {
    try {
      // const response = await axios.get(`/api/contacts/${id}`);
      const jakhr_url = process.env.REACT_APP_JAKHR_URL;
      
      const response = await axios.get(`${jakhr_url}/master/detail_employee/${id}`,{});
      let data = await response.data.data;

      // data.wallpaperUrl = `assets/images/avatars/default-wallpaper.jpg`;

      const res = await axios.get(`/userprofiles/${data.user_id}`);
      const profile = await res.data.data;
      if(profile){
        data.avatarUrl = profile.avatar ? profile.avatarUrl : null;
        data.wallpaperUrl = profile.wallpaper ? profile.wallpaperUrl : 'assets/images/avatars/default-wallpaper.jpg';
      }

      return data;
    } catch (error) {
      history.push({ pathname: `/apps/users` });

      return null;
    }
  }
);

export const addContact = createAsyncThunk(
  'contactsApp/contacts/addContact',
  async (contact, { dispatch, getState }) => {
    const response = await axios.post('/api/contacts', contact);

    const data = await response.data;

    return data;
  }
);

export const updateContact = createAsyncThunk(
  'contactsApp/contacts/updateContact',
  async (contact, { dispatch, getState }) => {
    const response = await axios.put(`/api/contacts/${contact.id}`, contact);

    const data = await response.data;

    return data;
  }
);

export const removeContact = createAsyncThunk(
  'contactsApp/contacts/removeContact',
  async (id, { dispatch, getState }) => {
    const response = await axios.delete(`/api/contacts/${id}`);

    await response.data;

    return id;
  }
);

export const selectContact = ({ contactsApp }) => contactsApp.contact;

const contactSlice = createSlice({
  name: 'contactsApp/contact',
  initialState: null,
  reducers: {
    newEmployee: (state, action) => EmployeeModel(),
    resetContact: () => null,
  },
  extraReducers: {
    [getContact.pending]: (state, action) => null,
    [getContact.fulfilled]: (state, action) => action.payload,
    [updateContact.fulfilled]: (state, action) => action.payload,
    [removeContact.fulfilled]: (state, action) => null,
  },
});

export const { resetContact, newEmployee } = contactSlice.actions;

export default contactSlice.reducer;
