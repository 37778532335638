import { lazy } from 'react';
import { getUserLoginInfo } from 'src/helpers/global';

const Logs = lazy(() => import('./Logs'));
const LogsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/logs',
      element: <Logs /> ,
    },
  ],
};

export default LogsConfig;
