import { lazy } from 'react';
import { getUserLoginInfo } from 'src/helpers/global';
// import DataManagement from './DataManagement';

const DataManagement  = lazy(() => import('./DataManagement'));
const DataManagementConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/data-management',
      element: <DataManagement /> ,
    },
  ],
};

export default DataManagementConfig;
