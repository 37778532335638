import { lazy } from 'react';

const ChangePassword = lazy(() => import('./ChangePassword'));

const ChangePasswordConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'apps/change-password',
      element: <ChangePassword />,
    },
  ],
};

export default ChangePasswordConfig;
