import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import withReducer from 'app/store/withReducer';
import { motion } from 'framer-motion';
import keycode from 'keycode';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Chat from './Chat';
import ContactList from './ContactList';
import reducer from './store';
import { getContacts, selectContacts, selectSelectedContactId } from './store/contactsSlice';
import { closeChatPanel, openChatPanel, selectChatPanelState } from './store/stateSlice';
import { getUserData } from './store/userSlice';
import { getChats } from './store/chatsSlice';
import { getJsoToken, getJsoTokenExists, getJsoUserToken, getToken, getUserLoginInfo } from 'src/helpers/global';
import axios from 'axios';
import { Button, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { showMessage } from 'app/store/fuse/messageSlice';
import jwtDecode from 'jwt-decode';

const Root = styled('div')(({ theme, opened }) => ({
  position: 'sticky',
  display: 'flex',
  top: 0,
  width: 70,
  maxWidth: 70,
  minWidth: 70,
  height: '100vh',
  zIndex: 1000,

  [theme.breakpoints.down('lg')]: {
    position: 'fixed',
    height: '100%',
    width: 0,
    maxWidth: 0,
    minWidth: 0,
  },

  ...(opened && {
    overflow: 'visible',
  }),

  ...(!opened && {
    overflow: 'hidden',
    animation: `hide-panel 1ms linear ${theme.transitions.duration.standard}`,
    animationFillMode: 'forwards',
  }),

  '& > .panel': {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 360,
    minWidth: 360,
    height: '100%',
    margin: 0,
    overflow: 'hidden',
    zIndex: 1000,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    transform: 'translate3d(0,0,0)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),

    ...(opened && {
      transform: theme.direction === 'rtl' ? 'translate3d(290px,0,0)' : 'translate3d(-290px,0,0)',
    }),

    [theme.breakpoints.down('lg')]: {
      left: 'auto',
      position: 'fixed',
      transform: theme.direction === 'rtl' ? 'translate3d(-360px,0,0)' : 'translate3d(360px,0,0)',
      boxShadow: 'none',
      width:120,
      minWidth:120,
      maxWidth: '100%',

      ...(opened && {
        transform: 'translate3d(0,0,0)',
        boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      }),
    },
  },

  '@keyframes hide-panel': {
    '0%': {
      overflow: 'visible',
    },
    '99%': {
      overflow: 'visible',
    },
    '100%': {
      overflow: 'hidden',
    },
  },
}));

const RootButton = styled(Tooltip)(({ theme, active }) => ({
  width: 70,
  minWidth: 70,
  flex: '0 0 auto',
  ...(active && {
    '&:after': {
      position: 'absolute',
      top: 8,
      right: 0,
      bottom: 8,
      content: "''",
      width: 4,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main,
    },
  }),
}));

const StyledUreadBadge = styled('div')(({ theme, value }) => ({
  position: 'absolute',
  minWidth: 18,
  height: 18,
  top: 4,
  left: 10,
  borderRadius: 9,
  padding: '0 5px',
  fontSize: 11,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
  zIndex: 10,
}));

const StyledStatus = styled('div')(({ theme, value }) => ({
  position: 'absolute',
  width: 12,
  height: 12,
  bottom: 4,
  left: 44,
  border: `2px solid ${theme.palette.background.default}`,
  borderRadius: '50%',
  zIndex: 10,

  ...(value === 'online' && {
    backgroundColor: '#4CAF50',
  }),

  ...(value === 'do-not-disturb' && {
    backgroundColor: '#F44336',
  }),

  ...(value === 'away' && {
    backgroundColor: '#FFC107',
  }),

  ...(value === 'offline' && {
    backgroundColor: '#646464',
  }),
}));

function ChatPanel(props) {
  const dispatch = useDispatch();
  const contacts = useSelector(selectContacts);
  const selectedContactId = useSelector(selectSelectedContactId);
  const state = useSelector(selectChatPanelState);
  const theme = useTheme();

  const token = getToken();
  const userLogin = getUserLoginInfo();
  const [companyModules, setCompanyModules] = useState([]);
  const [loading, setLoading] = useState(true);

  const ref = useRef();
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      return state && theme.direction === 'rtl' && dispatch(closeChatPanel());
    },
    onSwipedRight: () => {
      return state && theme.direction === 'ltr' && dispatch(closeChatPanel());
    },
  });

  const selectedContact = contacts.find((_contact) => _contact.id === selectedContactId);

  const handleDocumentKeyDown = useCallback(
    (event) => {
      if (keycode(event) === 'esc') {
        dispatch(closeChatPanel());
      }
    },
    [dispatch]
  );

  const appList = [
    {
      module_name: "JakHr",
      avatar: "assets/images/avatars/6.png",
      module_url: "http://45.77.245.25:3007/authentication/"+token
    },
    {
      module_name: "Planora",
      avatar: "assets/images/avatars/11.png",
      module_url: "http://planora-dev.jakpro.co.id/auth?token="+token
    }
  ];
  const wl = window.location;
  const url = wl.hostname;
  const protocol = wl.protocol;

  const getCompanyModules = async ()=>{
    try {
      
      const res = await axios.get(`/modulecompanies/${userLogin.companyId}`)
      let modules = await res.data.data;
      const list_modules = [];
      modules.forEach((element, i) => {
        element.avatar = `assets/images/avatars/icon-${element.module_name.toLowerCase()}-new.png`;
        const icons = JSON.parse(element.icon);
        if(icons.length > 0){
          element.avatar = icons[0].icon
        }
        list_modules.push(element)
      });
      setCompanyModules(list_modules)

      setTimeout(async () => {
        setLoading(false)
      }, 2000);

    } catch (error) {
      const errMsg = error?.response?.data?.message || error?.response?.data?.error || error?.response?.data?.detail || error?.error  || error?.message || "Terjadi kesalahan";
      dispatch(showMessage({message: errMsg}))
    }
  }

  useEffect(() => {
    getCompanyModules()
  }, [])
  
  
  useEffect(() => {
    dispatch(getUserData());
    dispatch(getContacts());
    dispatch(getChats());
    return () => {
      document.removeEventListener('keydown', handleDocumentKeyDown);
    };
  }, [dispatch, handleDocumentKeyDown]);

  useEffect(() => {
    if (state) {
      document.addEventListener('keydown', handleDocumentKeyDown);
    } else {
      document.removeEventListener('keydown', handleDocumentKeyDown);
    }
  }, [handleDocumentKeyDown, state]);

  /**
   * Click Away Listener
   */
  useEffect(() => {
    function handleDocumentClick(ev) {
      if (ref.current && !ref.current.contains(ev.target)) {
        dispatch(closeChatPanel());
      }
    }

    if (state) {
      document.addEventListener('click', handleDocumentClick, true);
    } else {
      document.removeEventListener('click', handleDocumentClick, true);
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick, true);
    };
  }, [state, dispatch]);

  const handleClickModule = async (app) => {
    

    let module_url = app.module_url;
    switch (app.module_name.toLowerCase()) {
      case 'jakhr':
        module_url = `${module_url}${token}/${url}/${protocol}`;
        break;
      case 'planora':
        module_url = `${module_url}${token}`;
        break;
      case 'kamban':
            module_url = `${module_url}${await getJsoTokenExists()}`;
        break;
      case "jaklearn":
            module_url = `${module_url}${JSO_USER_TOKEN}?redirect=https://jaklearn.com/home`;
        break;
      case "dashboard":
            module_url = `${module_url}`;
        break;
        
      default:
        module_url = `${module_url}${token}`;
        break;
    }
    window.open(module_url, '_blank');
    // window.open(app.module_url, '_blank');
  };
  return (
    <Root opened={state ? 1 : 0} {...handlers}>
      <div className="panel flex flex-col max-w-full" ref={ref}>
        <AppBar position="static" className="shadow-md">
          <Toolbar className="px-4">
            {(!state || !selectedContactId) && (
              <div className="flex flex-1 items-center px-8 space-x-12">
                {/* <IconButton
                  className=""
                  color="inherit"
                  onClick={(ev) => dispatch(openChatPanel())}
                  size="large"
                >
                </IconButton> */}
                <img width={40} src="assets/images/logo/logo.png" className='relative ' alt="" />
              
              </div>
            )}
            {state && selectedContact && (
              <div className="flex flex-1 items-center px-12">
                <Avatar src={selectedContact.avatar} />
                <Typography className="mx-16 text-16" color="inherit">
                  {selectedContact.name}
                </Typography>
              </div>
            )}
            <div className="flex px-4">
              <IconButton onClick={(ev) => dispatch(closeChatPanel())} color="inherit" size="large">
                <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Paper className="flex flex-1 flex-row justify-center lg:justify-start min-h-px shadow-0">
          <div className="flex shrink-0 flex-col overflow-y-auto py-8 overscroll-contain">
            {
              loading ?
              <>Loading ...</>
              :
              companyModules &&
                companyModules.map((contact, index) => {
                  return (
                    <motion.div key={index}>
                      <RootButton title={contact.module_name} placement="left">
                        <Button
                          onClick={()=>{
                            handleClickModule(contact)
                          }}
                          className={clsx(
                            'contactButton rounded-0 py-4 h-auto min-h-auto max-h-none',
                            selectedContactId === contact.id && 'active'
                          )}
                        >
                          {contact.unread && <StyledUreadBadge>{contact.unread}</StyledUreadBadge>}

                          <StyledStatus value={contact.status} />

                          <Avatar src={contact.avatar} alt={contact.module_name}>
                            {!contact.avatar || contact.avatar === '' ? contact.module_name[0] : ''}
                          </Avatar>
                        </Button>
                      </RootButton>
                    </motion.div>
                  );
                })
            }
          </div>
        </Paper>
      </div>
    </Root>
  );
}

export default withReducer('chatPanel', reducer)(memo(ChatPanel));
