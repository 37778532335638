import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import LandingPage from '../../../assets/backgroundImage/landingPage.jpg';
import MicrosoftIcon from '../../../assets/icons/Microsoft_365_logo.png';
import jwtService from '../../auth/services/jwtService';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { Try } from '@mui/icons-material';
// import { getUserLoginInfo } from 'src/helpers/global';
import FuseLoading from '@fuse/core/FuseLoading';
import axios from 'axios';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  new_password: yup.string().required('Please enter your new password.').min(4, 'New password is too short - must be at least 4 chars.'),
  confirm_password: yup.string().oneOf([yup.ref('new_password'), null], 'Confirm Password not match')
  .required('Confirm Password is required'),
});

const defaultValues = {
  confirm_password: '',
  new_password: '',
};

function ResetPasswordPage() {
  const jakhr_url = process.env.REACT_APP_JAKHR_URL;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const [isLoading, setLoading] = useState(false);
  const [successResetModal, setSuccessResetModal] = useState(false);

  useEffect(() => {
    setValue('new_password', '', { shouldDirty: true, shouldValidate: true });
    setValue('confirm_password', '', { shouldDirty: true, shouldValidate: true });
  }, [setValue]);

  async function onSubmit({ new_password, confirm_password }) {
    const reset_token = queryParams.get('reset_token');
    // setLoading(true);
    try {
      const url = jakhr_url + "/me/do_reset_password";
      const data = {
        reset_token,
        new_password,
        confirm_password
      }
      const response = await axios.put(url, data, {});
      dispatch(showMessage({ message: response.data.message, type: "success" }));
      // if(response.status == 201){
      // }else{
      //   dispatch(showMessage({ message: response.data.message, type: "error" }));
      // }
      setSuccessResetModal(true)
    } catch (error) {
      const errMsg = error?.response?.data?.message || error?.response?.data?.error || error?.response?.data?.detail || error?.error  || error?.message || "Terjadi kesalahan";
      dispatch(showMessage({message: errMsg}))
    }
  }
  
  const handleRedirectToLogin = async () =>{
    setSuccessResetModal(false)
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      navigate('/sign-in');
    }, 1000);
  }

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Box className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden">
        <img src={LandingPage} alt="" className="absolute inset-0 pointer-events-none w-screen h-screen object-cover" viewBox="0 0 960 540" />
      </Box>
      <Paper
        elevation={0}
        className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none absolute bg-transparent justify-center"
      >
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0 absolute md:left-[32%]"
        style={{
          top:"50%",
          transform: "translate(5%, -50%)"
        }}>
          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            Reset Password
          </Typography>
          <form name="loginForm" noValidate className="flex flex-col justify-center w-full mt-20" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="new_password"
              control={control}
              render={({ field }) => <TextField {...field} className="mb-24" label="New Password" autoFocus type="password" error={!!errors.new_password} helperText={errors?.new_password?.message} variant="outlined" required fullWidth />}
            />

            <Controller
              name="confirm_password"
              control={control}
              render={({ field }) => <TextField {...field} className="mb-24" label="Confirm Password" type="password" error={!!errors.confirm_password} helperText={errors?.confirm_password?.message} variant="outlined" required fullWidth />}
            />

            <Button variant="contained" color="secondary" className=" w-full mt-16" aria-label="Reset Password" disabled={_.isEmpty(dirtyFields) || !isValid} type="submit" size="large">
              Reset Password
            </Button>

          </form>
        </div>
      </Paper>
      {
        isLoading &&
        <div className="loading-loader">
          <FuseLoading/>
        </div>
      }
      {/* Modal reset success */}
      <Dialog open={successResetModal}>
        <DialogTitle>Password berhasil di perbarui!</DialogTitle>
        <DialogContent>
          <Typography>Anda akan dialihkan ke halaman login, Silahkan login kembali menggunakan password baru anda!</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='secondary' onClick={handleRedirectToLogin}>Ya, Login</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ResetPasswordPage;
