import _ from '@lodash';

const EmployeeModel = (data) =>
  _.defaults(data || {}, {
    id: "",
    employee_id: null,
    full_name: "",
    branch: {
        name: "",
        company: {
            name: ""
        }
    },
    division: "",
    position: "",
    job_level: ""
  });

export default EmployeeModel;
