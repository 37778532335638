import AcademyAppConfig from "./academy/AcademyAppConfig";
import CalendarAppConfig from "./calendar/CalendarAppConfig";
import ChatAppConfig from "./chat/ChatAppConfig";
import ContactsAppConfig from "./contacts/ContactsAppConfig";
import ECommerceAppConfig from "./e-commerce/ECommerceAppConfig";
import FileManagerAppConfig from "./file-manager/FileManagerAppConfig";
import MailboxAppConfig from "./mailbox/MailboxAppConfig";
import NotesAppConfig from "./notes/NotesAppConfig";
import ScrumboardAppConfig from "./scrumboard/ScrumboardAppConfig";
import TasksAppConfig from "./tasks/TasksAppConfig";
import HelpCenterAppConfig from "./help-center/HelpCenterAppConfig";
import ProfileAppConfig from "./profile/profileAppConfig";
import WidgetsAppConfig from "./widgets/WidgetsAppConfig";
import ChangePasswordConfig from "./change-password/ChangePasswordConfig"
import WallfeedAppConfig from "./wallfeed/wallfeedAppConfig";

const appsConfigs = [
  MailboxAppConfig,
  FileManagerAppConfig,
  ContactsAppConfig,
  CalendarAppConfig,
  ChatAppConfig,
  ECommerceAppConfig,
  ScrumboardAppConfig,
  AcademyAppConfig,
  NotesAppConfig,
  TasksAppConfig,
  HelpCenterAppConfig,
  ProfileAppConfig,
  WidgetsAppConfig,
  ChangePasswordConfig,
  WallfeedAppConfig,
];

export default appsConfigs;
