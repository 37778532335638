import { lazy } from 'react';

const Activity  = lazy(() => import('./Activity'));
const ActivityConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/activity',
      element: <Activity /> ,
    },
  ],
};

export default ActivityConfig;