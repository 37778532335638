import { lazy } from 'react';
import { getUserLoginInfo } from 'src/helpers/global';

const Companies = lazy(() => import('./Companies'));
const ProfileApp = lazy(() => import('../../apps/profile/ProfileApp'));
const CompaniesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/companies',
      element: <Companies /> ,
    },
  ],
};

export default CompaniesConfig;
