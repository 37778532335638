import { lazy } from 'react';

const Setting  = lazy(() => import('./Setting'));
const SettingConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/setting',
      element: <Setting /> ,
    },
  ],
};

export default SettingConfig;