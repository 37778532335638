import { lazy } from 'react';
import { getUserLoginInfo } from 'src/helpers/global';

const CryptoDashboardApp = lazy(() => import('./CryptoDashboardApp'));

const CryptoDashboardAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'apps/feed',
      element:  <CryptoDashboardApp />,
    },
  ],
};

export default CryptoDashboardAppConfig;
