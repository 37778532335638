import { lazy } from 'react';
import { getUserLoginInfo } from 'src/helpers/global';

const CompanyUsers = lazy(() => import('./CompanyUsers'));
const ProfileApp = lazy(() => import('../../apps/profile/ProfileApp'));

const CompanyUsersConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/users_company/:companyId/*',
      element: <CompanyUsers /> ,
    },
  ],
};

export default CompanyUsersConfig;
