import { lazy } from 'react';
import { getUserLoginInfo } from 'src/helpers/global';

const Company = lazy(() => import('./Company'));
const ProfileApp = lazy(() => import('../../apps/profile/ProfileApp'));

const CompanyConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/company/:companyId/*',
      element: <Company />,
    },
  ],
};

export default CompanyConfig;
