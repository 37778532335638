import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Button, IconButton } from '@mui/material';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';

function UserFeedback() {
  const [isButtonAHovered, setIsButtonAHovered] = useState(false);

  const toggleHover = () => {
    setIsButtonAHovered(!isButtonAHovered);
  };


  return (
    <div class="relative flex w-full"
          style={{
          position: 'fixed',
          bottom: '4%',
          right: '2%',
        }}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        >
        
        <IconButton 
        size="large" 
        class={`flex justify-center items-center fixed bottom-20 bg-blue-500 text-white font-semibold py-2 px-4 z-9999 rounded-full h-40 w-40 ease-in-out duration-300 transition-all ${isButtonAHovered ? 'right-224 mr-16' : 'right-20'}`}
        onClick={toggleHover}>
          <FuseSvgIcon>heroicons-outline:question-mark-circle</FuseSvgIcon>
        </IconButton>
        <IconButton 
        size="large" 
        class={`text-sm flex justify-center items-center fixed bottom-20 right-20 bg-blue-500 font-semibold py-2 rounded-full transition-all ease-in-out duration-500 h-40 text-white ${isButtonAHovered ? 'w-256 px-10 opacity-100' : 'w-256 px-0 opacity-0'}`} 
        onClick={()=>{
          window.open('https://forms.gle/75W9bWobLoT3iP5S7', '_blank')
        }}>
          {
            isButtonAHovered ?
            '- Laporkan kendala atau masukan'
            :
            ''
          }
        </IconButton>

    </div>
  )
}

export default UserFeedback;