import { lazy } from 'react';

const ProfileApp = lazy(() => import('../profile/ProfileApp'));

const wallfeedAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'apps/wallfeed',
      element: <ProfileApp /> ,
    },
  ],
};

export default wallfeedAppConfig;
