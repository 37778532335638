import axios from "axios";
import jwtDecode from "jwt-decode";

export function getToken() {  
    return localStorage.getItem('jwt_access_token');
}

export function getUserLoginInfo() {
    try {
        const token = getToken()
        if (token != null) {
            let decoded = jwtDecode(token);
            return decoded;
        }
        return null;
    } catch (error) {
        console.log(error);
    }
}

export function getConfigHeaders() {  
    const token = getToken()
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
            },
        };
    return config;
}

export function checkIsAdmin(){
    const userLogin = getUserLoginInfo()
    return userLogin.role == 'admin' ? true : false;
}

export function checkIsRoot(){
    const userLogin = getUserLoginInfo()
    return userLogin.role == 'root' ? true : false;
}

export async function getSiskaRole(){
    const userLogin = getUserLoginInfo()
    if (userLogin.employee_id) {
        try {
            const response = await axios.get(`/getRoleIsAdmin/${userLogin.employee_id}`)
            const {data} = await response.data;
            let siska_role = userLogin.role
            if(data.length > 0) siska_role =  'admin_siska'
            return siska_role;
        } catch (error) {
            console.log(error)
        }
    }
}

export async function getJsoToken() {
    try {
        const JSO_URL = process.env.REACT_APP_JSO_URL;
        const c_id = process.env.REACT_APP_QA_CLIENT_ID;
        const c_secret = process.env.REACT_APP_QA_CLIENT_SECRET;
    
        const formdata = new FormData();
        formdata.append("client_id", c_id);
        formdata.append("client_secret", c_secret);

        const response = await axios.post(`/login-jso`, formdata, {
            headers:{
                // "Content-Type":"multipart/form-data",
                // "Access-Control-Allow-Origin": "*",
                "Authorization": ""
            }
        })
        // using mock+++++++++++++
        // const response = await axios.get(`/api/login-jso`, formdata, {
        //     headers:{
        //         // "Content-Type":"multipart/form-data",
        //         // "Access-Control-Allow-Origin": "*",
        //         "Authorization": ""
        //     }
        // })
        if (response.data.token) {
            localStorage.setItem('JSO_ACCESS_TOKEN', response.data.token);
        }
        return response.data.token;
    } catch (error) {
        console.log('error while geting jso app token ', error);
        return error;
    }
};

const asyncTimeout = async () => {
    return new Promise((resolve) => {
        setTimeout(resolve, 4000);
    })
}

export async function getJsoUserToken(email) {
    try {
        const c_id = process.env.REACT_APP_QA_CLIENT_ID;
        const c_secret = process.env.REACT_APP_QA_CLIENT_SECRET;

        let formdata = new FormData();
        formdata.append("client_id", c_id);
        formdata.append("client_secret", c_secret);

        const res = await axios.post(`/login-jso`, formdata, {
            headers:{
                // "Content-Type":"multipart/form-data",
                // "Access-Control-Allow-Origin": "*",
                "Authorization": ""
            }
        })

        await asyncTimeout();
        formdata = new FormData();
        formdata.append("email", email);
        const response = await axios.post(`/users/user_token`, formdata, {
            headers:{
                // "Content-Type":"application/json",
                // "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${res.data.token}`
            }
        })
        if (response.data.user_token) {
            localStorage.setItem('JSO_USER_TOKEN', response.data.user_token);
        }
        return response.data.user_token
    } catch (error) {
        console.log(error);
    }
};


export function validateFileImage(file_type){
    const allowedTypes = ['image/png', 'image/jpeg','image/jpg'];
    if(allowedTypes.includes(file_type)){
        return true;
    }else{
        return false;
    }
}

export async function getJsoTokenExists () {  
    let JSO_USER_TOKEN = await localStorage.getItem('JSO_USER_TOKEN')
    const userLogin = getUserLoginInfo();
    if (!JSO_USER_TOKEN || JSO_USER_TOKEN === undefined) {
        JSO_USER_TOKEN = await getJsoUserToken(userLogin.email)
    }else{
        const decoded = jwtDecode(JSO_USER_TOKEN)
        const now = new Date();
        const exp = new Date(decoded.exp * 1000);
        if (now > exp) {
            JSO_USER_TOKEN = await getJsoUserToken(userLogin.email)
        }
    }
    return JSO_USER_TOKEN;
}