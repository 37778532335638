import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import jwtServiceConfig from './jwtServiceConfig';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    // set rto
    // axios.interceptors.request.use(config => {
    //   config.timeout = 30000;
    //   return config;
    // });
    
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (err.code === 'ECONNABORTED' && err.message.includes('timeout')) {
            this.emit('onRequestTimeOut')
            reject(err)
          }else if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            // if you ever get an unauthorized response, logout the user
            // this.emit('onAutoLogout', 'Email/Password salah');
            // this.setSession(null);
            this.emit('onPermissionDenied', false);
          }      
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    try {
      const access_token = this.getAccessToken();
      if (!access_token) {
        this.emit('onNoAccessToken');
  
        return;
      }
  
      if (this.isAuthTokenValid(access_token)) {
        this.setSession(access_token);
        this.emit('onAutoLogin', true);
      } else {
        this.setSession(null);
        this.emit('onAutoLogout', false);
        // this.emit('onPermissionDenied', false);
      }
      
    } catch (error) {
      console.log(error);
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.signUp, data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
          this.emit('onLogin', response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.signIn, {
          email,
          password,
        })
        .then((response) => {
          if (response.data.token && response.data.token !== null) {
            const token = response.data.token;
            this.setSession(token);
            const decoded = jwtDecode(token);
            let change_decoded = decoded;
            if (change_decoded.role !== 'root') {
              change_decoded.role = response.data.is_admin ?? 'admin_siska';
            }
            resolve(change_decoded);
            this.emit('onLogin', decoded);
          } else {
            reject(response);
          }
        }).catch((err)=>{
          console.log(err);
        });
    });
  };
  
  signInWithSSO = (accessToken) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.signInSSO, {
          email: '',
          password: '',
          sso_token: accessToken
        })
        .then((response) => {
          if (response.data.token) {
            const token = response.data.token;
            this.setSession(token);
            const decoded = jwtDecode(token);
            let change_decoded = decoded;
            if (change_decoded.role !== 'root') {
              change_decoded.role = response.data.is_admin ?? 'admin_siska';
            }
            resolve(change_decoded);
            this.emit('onLogin', decoded);
          } else {
            reject(response);
          }
        }).catch((err)=>{
          console.log(err);
        });
    });
  };

  checkEmail = (email) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.checkLogin, {
          email,
        })
        .then((response) => {
          if (response.data.is_sso !== undefined && response.data.is_sso !== null) {
            resolve(response);
          } else {
            reject(response);
          }
        }).catch((err)=>{
          reject(err)
        });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
    //   // axios
    //   //   .get(jwtServiceConfig.accessToken, {
    //   //     params: {
    //   //       access_token: this.getAccessToken(),
    //   //     },
    //   //   })
    //   //   .then((response) => {
    //   //     if (response.data.token) {
    //   //       const token = response.data.token;
    //   //       this.setSession(token);

    //   //       const decoded = jwtDecode(token);

    //   //       resolve(decoded);
    //   //     }
    //   //   })
    //   // .catch((error) => {
    //   //   console.error(error.message);
    //   //   this.logout();
    //   //   reject(new Error('Failed to login with token.'));
    //   // });

      // temporary fix sebelum ada api endpoint untuk validate access_token
      const token = localStorage.getItem('jwt_access_token');
      if (token) {
        this.setSession(token);
        const decoded = jwtDecode(token);
        resolve(decoded);
      } else {
        reject(new Error('No token found in local storage.'));
      }
    }).catch((err)=>{
      console.log(err);
    });
  };

  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('jwt_access_token');
      localStorage.removeItem('JSO_USER_TOKEN');
      localStorage.removeItem('JSO_ACCESS_TOKEN');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
    this.emit('onLogout', 'Logged out');
  };

  isAuthTokenValid = (access_token) => {
    try {
      if (!access_token) {
        return false;
      }
      const decoded = jwtDecode(access_token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        console.warn('access token expired');
        return false;
      }
  
      return true;
    } catch (error) {
      console.log(error);
      
    }
  };

  getAccessToken = () => {
    const token = window.localStorage.getItem('jwt_access_token');
    return token;
  };
}

const instance = new JwtService();

export default instance;
