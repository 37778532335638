const jwtServiceConfig = {
  // signIn: 'auth/login',//jakhr
  signIn: "/login",
  signInSSO: "/login-sso",
  // signUp: 'api/auth/sign-up',
  accessToken: "api/auth/access-token",
  // updateUser: 'api/auth/user/update',
  checkLogin: "/check-login",
};

export default jwtServiceConfig;
