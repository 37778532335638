import { lazy } from 'react';

const Api  = lazy(() => import('./Api'));
const ApiConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/api',
      element: <Api /> ,
    },
  ],
};

export default ApiConfig;
