import * as React from "react";
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
// import { Button } from '@mui/base';
import Button from "@mui/material/Button";
import detail from "./store/preview.png";
import hapus from "./store/delete.png";
import moment from "moment";

// import AddIcon from "@mui/icons-material/Add";
// import { Link } from "react-router-dom";
// import CompaniesHeader from "./CompaniesHeader";
// import FusePageCarded from "@fuse/core/FusePageCarded/FusePageCarded";
// import { useThemeMediaQuery } from "@fuse/hooks";

// import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import { useDispatch } from "react-redux";
// import { showMessage } from "app/store/fuse/messageSlice";

import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from "react-router-dom";
import CompaniesHeader from "./CompaniesHeader";
import FusePageCarded from "@fuse/core/FusePageCarded/FusePageCarded";
import { useThemeMediaQuery } from "@fuse/hooks";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";
import { getConfigHeaders, getSiskaRole, getUserLoginInfo } from "src/helpers/global";
import { AccountTree } from "@mui/icons-material";
import FusePageCardedCustome from "@fuse/core/FusePageCarded/FusePageCardedCustome";

export default function ResponsiveGrid() {
  const userLogin = getUserLoginInfo();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const jakhr_url = process.env.REACT_APP_JAKHR_URL;

  const [data, setData] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedPost, setSelectedPost] = useState(null);
  const [companyId, setCompanyId] = useState(null);


  const config = getConfigHeaders();

  const fetchData = async () => {
    try {
      // const respon = await axios.get(`/posts?page=1&pagesize=20`, {}, config);
      const respon = await axios.get(
        `${jakhr_url}/master/get_companies?page=${currentPage}&perPage=${rowsPerPage}`,
        {},
        config
      );
      const res = respon.data;
      setData(res.data);
      setCurrentPage(res.currentPage);
      setTotalPages(res.totalPages);
    } catch (error) {
      const errMsg = error?.response?.data?.message || error?.error || "Terjadi kesalahan";
      dispatch(showMessage({message: errMsg}))
    }
  };

  // Fungsi untuk memperbarui postingan
  const deletePost = async (postId) => {
    try {
      // const response = await axios.delete(`/posts/${postId}`, config);

    } catch (error) {
      const errMsg = error?.response?.data?.message || error?.error || "Terjadi kesalahan";
      dispatch(showMessage({message: errMsg}))
    }
  };

  useEffect(() => {
    
    if (userLogin.role !== 'root') {
      // getSiskaRole()
      // .then((response)=>{
      //   if (response !== 'admin') {
      //   }
      // })
      navigate(`/apps/profile/${userLogin?.id}`);
    }

    fetchData();

  }, [currentPage]);

  // if (!data) {
  //   return null;
  // }

  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 40 },
    show: { opacity: 1, y: 0 },
  };

  // show modal
  const handleOpenModal = () => {
    setModal(true);
  };

  // hide modal
  const handleCloseModal = () => {
    setModal(false);
  };

  // change page when pagination button is clicked
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `${jakhr_url}/master/delete_company/${companyId}`
      );
      dispatch(showMessage({ message: "Company has been deleted" }));
      setModal(false);
      fetchData();
    } catch (error) {
      const errMsg = error?.response?.data?.message || error?.error || "Terjadi kesalahan";
      dispatch(showMessage({message: errMsg}))
    }
  };

  return (
    <div className="flex flex-col flex-auto justify-center w-full max-w-5xl mx-auto p-24 sm:p-32">
      <FusePageCardedCustome
          header={
            <CompaniesHeader />
          }
          content={
            <>
              <motion.div
                variants={container}
                initial="hidden"
                animate="show"
                className="w-full"
              >
                <Card
                  component={motion.div}
                  variants={item}
                  className="flex flex-col w-full px-32 pt-24"
                >
                  {/* List post */}

                  <div className="w-full mb-8 overflow-hidden rounded-lg shadow-xs">
                    <div className="w-full overflow-x-auto">
                      <table className="w-full">
                        <thead>
                          <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                            <th className="px-4 py-3 text-[12px] w-1/3">Name</th>
                            <th className="px-4 py-3 text-[12px] w-1/4">Alias</th>
                            <th className="px-4 py-3 text-[12px] w-1/4">Address</th>
                            <th className="px-4 py-3 text-[12px] w-1/4">Status</th>
                            <th className="px-4 py-3 text-[12px] w-1/4">
                              Created At
                            </th>
                            <th className="px-4 py-3 text-[12px] w-1/4">Action</th>
                          </tr>
                        </thead>
                        {data.map((data) => (
                          <tbody
                            className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800 text-left"
                            key={data.id}
                          >
                            <tr className="hover:bg-gray-100">
                              <td className="p-7">{data.name}</td>
                              <td className="p-7">{data.alias}</td>
                              <td className="p-7">{data.address}</td>
                              <td className="p-7">
                                {data.is_active ? (
                                  <span className="bg-green-500 px-8 rounded-8 text-white">
                                    Active
                                  </span>
                                ) : (
                                  <span className="bg-red-500 px-8 rounded-8 text-white">
                                    In Active
                                  </span>
                                )}
                              </td>
                              <td className="p-7">
                                {moment(data.created_at).format('DD-MM-YYYY')}
                              </td>
                              <td className="py-7 flex flex-row text-center items-center justify-start gap-6">
                                <IconButton
                                  aria-label="edit"
                                  component={Link}
                                  to={"/dashboards/company/" + data.id}
                                  color="secondary"
                                  variant="contained"
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="edit"
                                  component={Link}
                                  to={"/dashboards/users_company/" + data.id}
                                  color="warning"
                                  variant="contained"
                                >
                                  <PersonAddIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="edit"
                                  component={Link}
                                  to={"/dashboards/branch_company/" + data.id}
                                  color="success"
                                  variant="contained"
                                >
                                  <AccountTree />
                                </IconButton>
                                {/* <IconButton
                                  aria-label="delete"
                                  color="danger"
                                  variant="contained"
                                  onClick={() => {
                                    setCompanyId(data.id); // Set data postingan yang dipilih ke dalam state selectedPost
                                    return setModal(true);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton> */}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                    <div className="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
                      <p className="flex items-center col-span-3 text-sm">
                        Showing {currentPage * rowsPerPage - rowsPerPage + 1}-
                        {currentPage * rowsPerPage} of {totalPages} Page
                      </p>
                      <span className="col-span-2"></span>

                      <span className="flex col-span-4 mt-2 sm:mt-auto sm:justify-end">
                        <Stack spacing={2}>
                          <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                          />
                        </Stack>
                      </span>
                    </div>
                  </div>
                </Card>

                {/* Modal */}
                <Dialog open={modal} onClose={handleCloseModal}>
                  <DialogTitle>Peringatan!</DialogTitle>
                  <DialogContent>
                    {companyId && (
                      <>
                        <Typography>Data ini akan di hapus, lanjutkan?</Typography>
                      </>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseModal}>Tidak</Button>
                    <Button onClick={handleDelete}>Ya</Button>
                  </DialogActions>
                </Dialog>
              </motion.div>
            </>
          }
          scroll={isMobile ? 'normal' : 'content'}
        />
    </div>
  );
}
