import { lazy } from "react";
import { Navigate } from "react-router-dom";
import WidgetsApp from "./WidgetsApp";

const Leaves = lazy(() => import("./leaves/Leaves"));

const WidgetsAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "apps/widgets",
      element: <WidgetsApp />,
      children: [
        {
          path: "",
          element: <Navigate to="/apps/widgets/leaves" />,
        },
        {
          path: "leaves",
          element: <Leaves />,
        },
      ],
    },
  ],
};

export default WidgetsAppConfig;
