import { lazy } from 'react';
import { getUserLoginInfo } from 'src/helpers/global';

const Modules = lazy(() => import('./Modules'));
const ModulesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/modules',
      element: <Modules /> ,
    },
  ],
};

export default ModulesConfig;
