/**
 * Authorization Roles
 */
const authRoles = {
  root: ['root'],
  admin: ['admin', 'manager'],
  staff: ['admin','admin_siska', 'staff', 'manager', 'payroll'],
  user: ['admin','admin_siska', 'staff', 'manager', 'payroll', 'user', 'karyawan', 'pegawai'],
  onlyGuest: [],
};

export default authRoles;
