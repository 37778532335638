import { lazy } from 'react';
import { getUserLoginInfo } from 'src/helpers/global';

const ProfileApp = lazy(() => import('./ProfileApp'));
const Companies = lazy(() => import('../../dashboards/companies/Companies'));
const userLogin = getUserLoginInfo();

const profileAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'apps/profile',
      element: <ProfileApp /> ,
    },
    {
      path: 'apps/profile/:userId/*',
      element: <ProfileApp /> ,
    },
  ],
};

export default profileAppConfig;
