import AnalyticsDashboardAppConfig from './analytics/AnalyticsDashboardAppConfig';
import ProjectDashboardAppConfig from './project/ProjectDashboardAppConfig';
import FinanceDashboardAppConfig from './finance/FinanceDashboardAppConfig';
import CryptoDashboardAppConfig from './crypto/CryptoDashboardAppConfig';
import CompaniesConfig from './companies/CompaniesConfig';
import CompanyConfig from './company/CompanyConfig';
import CompanyUsersConfig from './company_users/CompanyUsersConfig';
import CompanyBranchConfig from './company_branch/CompanyBranchConfig';
import ModulesConfig from './modules/ModulesConfig';
import ModuleConfig from './module/ModuleConfig';
import JakproDashboardConfig from './jakpro/JakproDashboardConfig';
import LogsConfig from './logs/LogsConfig';
import RolesConfig from './roles/RolesConfig';
import RoleConfig from './role/RoleConfig';
import DataManagementConfig from './dataManagement/DataManagementConfig';
import CodeGeneratorConfig from './codeGenerator/CodeGeneratorConfig';
import WorkflowConfig from './workflow/WorkflowConfig';
import JobsConfig from './jobs/JobsConfig';
import ApiConfig from './api/ApiConfig';
import ActivityConfig from './activity/ActivityConfig';
import ReportConfig from './report/ReportConfig';
import SettingConfig from './setting/SettingConfig';

const dashboardsConfigs = [
  AnalyticsDashboardAppConfig,
  ProjectDashboardAppConfig,
  FinanceDashboardAppConfig,
  CryptoDashboardAppConfig,
  CompaniesConfig,
  CompanyConfig,
  CompanyUsersConfig,
  CompanyBranchConfig,
  ModulesConfig,
  ModuleConfig,
  JakproDashboardConfig,
  LogsConfig,
  RolesConfig,
  // RoleConfig,
  DataManagementConfig,
  CodeGeneratorConfig,
  WorkflowConfig,
  JobsConfig,
  ApiConfig,
  ActivityConfig,
  ReportConfig,
  SettingConfig
];

export default dashboardsConfigs;
