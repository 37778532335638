import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react';
import LandingPage from '../../../assets/backgroundImage/landingPage.jpg';
import MicrosoftIcon from '../../../assets/icons/Microsoft_365_logo.png';
import jwtService from '../../auth/services/jwtService';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { Try } from '@mui/icons-material';
// import MicrosoftLogin from "react-microsoft-login";
// import { getUserLoginInfo } from 'src/helpers/global';
import FuseLoading from '@fuse/core/FuseLoading';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { motion } from 'framer-motion';
import UserFeedback from 'app/shared-components/UserFeedback';



/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email('You must enter a valid email').required('You must enter a email'),
  password: yup.string().required('Please enter your password.').min(4, 'Password is too short - must be at least 4 chars.'),
});

const defaultValues = {
  email: '',
  password: '',
  remember: true,
};
// const userLogin = getUserLoginInfo()

// const config = {
//   // appId: "e8bf2b55-a82e-488f-9444-34d5fbf4f5be",
//   // appId: "59538ae0-0806-43cd-906b-0ff7029afff1",
//   appId: "78622d6d-e903-4c1c-b3d8-4f52a45bb69f",
//   // redirectUri: "https://siska-dev.jakpro.co.id/sign-in",
//   redirectUri: "http://localhost:3000",
//   scopes: ['user.read'],
//   authority: 'https://login.microsoftonline.com/common',
// }

function SignInPage() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  
  const { control, formState, handleSubmit, setError, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [shouldChangePasswordModal, setShouldChangePasswordModal] = useState(false);
  const [changePasswordMessage, setChangePasswordMessage] = useState('');
  const [isChangeEmail, setIsChangeEmail] = useState(false);
  const [isSSO, setIsSSO] = useState(undefined);

  useEffect(() => {
    // setValue('email', 'user1@user.user', { shouldDirty: true, shouldValidate: true });
    // setValue('password', 'password', { shouldDirty: true, shouldValidate: true });
    if (isAuthenticated) checkLoginSSO();
  }, [isAuthenticated]);

  function onSubmit({ email, password }) {
    setLoading(true);
    jwtService
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        setLoading(false);
        // Simpan metode login sebagai username-password
      localStorage.setItem('login_method', 'username-password');
        // No need to do anything, user data will be set at app/auth/AuthContext
      })
      .catch(_errors => {
        const errMsg = _errors.data.message
        if (_errors.data.token !== undefined) {
          setShouldChangePasswordModal(true)
          setChangePasswordMessage(errMsg)
        }else{
          dispatch(showMessage({ message: `Login failed, ${errMsg}`}));
        }
        setLoading(false);
        // _errors.forEach((error) => {
        //   setError(error.type, {
        //     type: 'manual',
        //     message: error.message,
        //   });
        // });
      });
  }

  async function checkLoginSSO() {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    })
    .then((response) => {
        setLoading(true);
        jwtService
          .signInWithSSO(response.accessToken)
          .then((user) => {
            setLoading(false);
            // Simpan metode login sebagai username-password
      localStorage.setItem('login_method', 'sso-microsoft');
            // No need to do anything, user data will be set at app/auth/AuthContext
          })
          .catch(_errors => {
            const errMsg = _errors.data.message
            if (_errors.data.token !== undefined) {
            setShouldChangePasswordModal(true)
            setChangePasswordMessage(errMsg)
            }
            dispatch(showMessage({ message: `Login failed, ${_errors.data.message}`}));
            setLoading(false);
            sessionStorage.clear();
            
            // _errors.forEach((error) => {
            //   setError(error.type, {
            //     type: 'manual',
            //     message: error.message,
            //   });
            // });
          });
        // callMsGraph(response.accessToken).then((response) => setGraphData(response));
    });
  }


  async function doLoginSSO() {
    instance.loginRedirect(loginRequest).catch(alert);
  }
  
  async function onLoginSSO(err, data, instance) {
    if (err){
      dispatch(showMessage({ message: `Login failed, ${err.toString()}`}))
    }else {
      setLoading(true);
      jwtService
        .signInWithSSO(data.accessToken)
        .then((user) => {
          setLoading(false);
          // No need to do anything, user data will be set at app/auth/AuthContext
        })
        .catch(_errors => {
          const errMsg = _errors.data.message
          dispatch(showMessage({ message: `Login failed, ${errMsg}`}));
          setLoading(false);
          // _errors.forEach((error) => {
          //   setError(error.type, {
          //     type: 'manual',
          //     message: error.message,
          //   });
          // });
        });
    }
  };

  const handleCloseModal = ()=>{
    setShouldChangePasswordModal(false)
    setChangePasswordMessage('')
  }

  const handleKeyUp = async (e) => {
    if (e.key === 'Enter') {
      if(await checkIsSSO() !== undefined){
        if(await checkIsSSO()){
          setIsSSO(true)
        }else{
          setIsSSO(false)
        }
        setIsChangeEmail(false)
      }
    }else{
      setIsSSO(undefined)
      setIsChangeEmail(true)
    }
  };

  const handleCheckEmail = async (e) => {
    setIsChangeEmail(false)
    if(await checkIsSSO() !== undefined){
      if(await checkIsSSO()){
        setIsSSO(true)
      }else{
        setIsSSO(false)
      }
    }
  };

  async function checkIsSSO() {
    try {
      const response = await jwtService.checkEmail(getValues('email'))
      console.log('here',response);
      const {data} = response;
      // if (getValues('email') == 'deni.test@jakarta-infrastruktur.com') {
      if (data.is_sso) {
        return true;
      }else{
        return false;
      }
      
    } catch (_errors) {
      setIsChangeEmail(true)
      console.log(_errors)
      const errMsg = _errors?.response?.data?.message
      dispatch(showMessage({ message: `Email tidak terdaftar, periksa kembali email yang anda masukan!`}));
      return undefined;
    }
  }

  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Box className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden">
        <img src={LandingPage} alt="" className="absolute inset-0 pointer-events-none w-screen h-screen object-cover" viewBox="0 0 960 540" />
      </Box>
      <Paper
        elevation={0}
        className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none absolute bg-transparent justify-center"
      >
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0 absolute md:left-[32%]"
        style={{
          top:"50%",
          transform: "translate(5%, -40%)"
        }}>
          <form name="loginForm" noValidate className="flex flex-col justify-center w-full mt-20" onSubmit={handleSubmit(onSubmit)}>
            <Typography className="mb-20" color="text.secondary">
              Masukan alamat email anda untuk memulai.
            </Typography>
            <Controller
              name="email"
              control={control}
              render={({ field }) => <TextField {...field} className="mb-24" label="Email" autoFocus type="email" error={!!errors.email} helperText={errors?.email?.message} variant="outlined" required fullWidth onKeyUp={handleKeyUp} />}
            />
            {
              isSSO === false &&
              <motion.div 
              variants={container}
              initial="hidden"
              animate="show">
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => <TextField {...field} className="mb-24" label="Password" type="password" error={!!errors.password} helperText={errors?.password?.message} variant="outlined" required fullWidth />}
                />

                <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
                  <Controller
                    name="remember"
                    control={control}
                    render={({ field }) => (
                      <FormControl>
                        <FormControlLabel label="Remember me" control={<Checkbox size="small" {...field} />} />
                      </FormControl>
                    )}
                  />

                  {/* <Link className="text-md font-medium" to="/pages/auth/forgot-password">
                    Forgot password?
                  </Link> */}
                </div>

                <Button variant="contained" color="secondary" className=" w-full mt-16" aria-label="Sign in" disabled={_.isEmpty(dirtyFields) || !isValid} type="submit" size="large">
                  Sign in
                </Button>
              </motion.div>
            }

            {
              isSSO === true &&
              <motion.div 
              variants={container}
              initial="hidden"
              animate="show">
                <div className="flex items-center mt-32">
                  <div className="flex-auto mt-px border-t" />
                  <Typography className="mx-8" color="text.secondary">
                    Lanjutkan dengan Akun Microsoft
                  </Typography>
                  <div className="flex-auto mt-px border-t" />
                </div>
                {console.log("FUCK")}
                <div className="flex justify-center items-center mt-32 space-x-16">
                  {/* <Button variant="contained" color="secondary" className=" w-full mt-16" aria-label="Sign in" type="button" size="large" onClick={doLoginSSO}>
                    Sign in SSO
                  </Button> */}
                    <Button variant="outlined" className="flex-auto p-5" type="button" onClick={doLoginSSO}>
                      <img src={MicrosoftIcon} alt="" className="w-[120px]" />
                    </Button>
                  {/* <MicrosoftLogin clientId={config.appId} withUserData={true} redirectUri={config.redirectUri} useLocalStorageCache={true} debug={false} authCallback={onLoginSSO} forceRedirectStrategy={false}/> */}
                </div>
              </motion.div>
            }
          </form>
          {
            isChangeEmail &&
            <Button variant="contained" color="secondary" className=" w-full" aria-label="Lanjutkan" type="button" size="large" onClick={handleCheckEmail}>
              Lanjutkan
            </Button>
          }
        </div>
      </Paper>
      {isLoading && <div className="loading-loader">
        <FuseLoading/>
      </div>}
      {/* Modal reset success */}
      <Dialog open={shouldChangePasswordModal}>
        <DialogTitle className='text-28 font-bold'>Selamat Datang!</DialogTitle>
        <DialogContent>
          <Typography className='text-14 font-normal'>{changePasswordMessage}. Periksa juga folder spam anda jika tidak dapat menemukan email dari kami di inbox. Anda hanya perlu melakukan ini satu kali.</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='secondary' onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
      <UserFeedback/>
    </div>
  );
}

export default SignInPage;
