import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { motion } from 'framer-motion';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChat } from './store/chatSlice';
import { selectContacts, selectSelectedContactId } from './store/contactsSlice';
import { openChatPanel } from './store/stateSlice';
import ContactButton from './ContactButton';
import { selectChats } from './store/chatsSlice';
import axios from 'axios';
import { getToken, getUserLoginInfo } from 'src/helpers/global';

const Root = styled(FuseScrollbars)(({ theme }) => ({
  background: theme.palette.background.paper,
}));

function ContactList(props) {
  const {companyModules} = props;
  const dispatch = useDispatch();
  const contacts = useSelector(selectContacts);
  const selectedContactId = useSelector(selectSelectedContactId);
  const chats = useSelector(selectChats);
  const contactListScroll = useRef(null);

  const scrollToTop = () => {
    contactListScroll.current.scrollTop = 0;
  };
  
  const token = getToken();
  const appList = [
    {
      module_name: "JakHr",
      avatar: "assets/images/avatars/6.png",
      module_url: "http://45.77.245.25:3007/authentication/"+token
    },
    {
      module_name: "Planora",
      avatar: "assets/images/avatars/11.png",
      module_url: "http://planora-dev.jakpro.co.id/auth?token="+token
    }
  ];
  
  return (
    <Root
      className="flex shrink-0 flex-col overflow-y-auto py-8 overscroll-contain"
      ref={contactListScroll}
      option={{ suppressScrollX: true, wheelPropagation: false }}
    >
      {useMemo(() => {
        const chatListContacts =
          contacts.length > 0 && chats.length > 0
            ? chats.map((_chat) => ({
                ..._chat,
                ...contacts.find((_contact) => _contact.id === _chat.contactId),
              }))
            : [];

        const handleContactClick = (app) => {
          // dispatch(openChatPanel());
          // dispatch(getChat(contactId));
          // scrollToTop();
          window.open(app.module_url, '_blank');
        };

        const container = {
          show: {
            transition: {
              staggerChildren: 0.05,
            },
          },
        };

        const item = {
          hidden: { opacity: 0, scale: 0.6 },
          show: { opacity: 1, scale: 1 },
        };
        return (
          appList.length > 0 && (
            <>
              <motion.div
                variants={container}
                initial="hidden"
                animate="show"
                className="flex flex-col shrink-0"
              >
                {appList &&
                  appList.map((app, index) => {
                    return (
                      <motion.div variants={item} key={index}>
                        <ContactButton
                          contact={app}
                          selectedContactId={selectedContactId}
                          onClick={handleContactClick}
                        />
                      </motion.div>
                    );
                  })}
                <Divider className="mx-24 my-8" />
                {/* {appList.map((contact) => {
                  const chatContact = chats.find((_chat) => _chat.contactId === contact.id);

                  return !chatContact ? (
                    <motion.div variants={item} key={contact.id}>
                      <ContactButton
                        contact={contact}
                        selectedContactId={selectedContactId}
                        onClick={handleContactClick}
                      />
                    </motion.div>
                  ) : null;
                })} */}
              </motion.div>
            </>
          )
        );
      }, [chats, contacts, dispatch, selectedContactId])}
    </Root>
  );
}

export default memo(ContactList);
