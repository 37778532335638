import { lazy } from 'react';

const Module = lazy(() => import('./Module'));

const ModuleConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/module/:moduleId/*',
      element: <Module />,
    },
  ],
};

export default ModuleConfig;
