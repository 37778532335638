import { lazy } from 'react';
import { getUserLoginInfo } from 'src/helpers/global';
// import DataManagement from './DataManagement';

const CodeGenerator  = lazy(() => import('./CodeGenerator'));
const CodeGeneratorConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/code-generator',
      element: <CodeGenerator /> ,
    },
  ],
};

export default CodeGeneratorConfig;
