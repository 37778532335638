import { memo } from 'react';
import ChatPanel from '../../shared-components/chatPanel/ChatPanel';
import QuickPanel from '../../shared-components/quickPanel/QuickPanel';
import NotificationPanel from '../../shared-components/notificationPanel/NotificationPanel';
import { getUserLoginInfo } from 'src/helpers/global';

function RightSideLayout1(props) {
  const userLogin = getUserLoginInfo();
  return (
    <>
      {
        userLogin.role !== 'root' &&
        <ChatPanel />
      }

      {/* <QuickPanel /> */}

      <NotificationPanel />
    </>
  );
}

export default memo(RightSideLayout1);
